import { Expose, Type } from 'class-transformer';
import Product from '~/models/product';
import 'reflect-metadata';
import Banner from '~/models/banner';
import BadgeImg from '~/models/badge-img';

export default class ProductResults {
  @Expose({ name: 'contains_alcohol_products' })
  containerAlcoholProducts!: boolean;

  @Expose()
  title?: string;

  @Expose()
  name!: string;

  @Type(() => Product)
  products!: Product[] | null;

  @Expose({ name: 'quantity_of_products_to_display' })
  quantityOfProductsToDisplay?: number;

  @Expose({ name: 'video_url' })
  @Type(() => String)
  videoUrl?: string;

  @Expose({ name: 'video_background' })
  @Type(() => BadgeImg)
  videoBackground?: BadgeImg;

  @Expose({ name: 'mobile_video_url' })
  @Type(() => String)
  mobileVideoUrl?: string;

  @Expose({ name: 'mobile_video_background' })
  @Type(() => BadgeImg)
  mobileVideoBackground?: BadgeImg;

  @Expose({ name: 'background_image' })
  backgroundImage?: boolean;

  @Expose({ name: 'redirect_url' })
  @Type(() => String)
  redirectUrl?: string;

  @Expose({ name: 'banner_files_1' })
  bannerFiles1?: Banner[];

  @Expose({ name: 'banner_files_2' })
  bannerFiles2?: Banner[];

  @Expose({ name: 'banner_files_3' })
  bannerFiles3?: Banner[];

  @Type(() => Banner)
  @Expose({ name: 'banner_files' })
  bannerFiles?: Banner[];
}
